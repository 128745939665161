import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
//  font roboto
import '@fontsource/roboto';
//  styles
import './index.css';

const ContentLayout = (props) => {
  const { children, ...rest } = props;

  return (
    <>
      <CssBaseline enableColorScheme />
      {children}
    </>
  );
};

export default ContentLayout;
